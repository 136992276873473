import { defineStore } from 'pinia'
import Client from 'App/Api/Client'
import { useLoadsStore } from './Loads'
import { find } from './helpers/getters'
import helperActions from './helpers/actions'
import StateFactory from './helpers/StateFactory'

/**
 * The API client instance.
 *
 * @type {Client}
 */
const api = new Client()

/**
 * Load segments Pinia store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useLoadSegmentsStore = defineStore('load-segments', {
    /**
     * Load segments' state.
     *
     * @return  {Object}
     */
    state: () => StateFactory.entity({}),

    /**
     * Load segments' getters.
     *
     * @type  {Object}
     */
    getters: {
        /**
         * Get all the segments.
         *
         * @param {Object} state
         * @return  {Array}
         */
        segments(state) {
            return state.ids.map((id) => state.items[id])
        },

        /**
         * Filter segments by load id.
         *
         * @return {Array}
         */
        filterByLoadId() {
            return (loadId) => {
                const id = parseInt(loadId)

                return this.segments.filter((segment) => segment.load_id === id)
            }
        },

        find,
    },

    /**
     * Load segments' actions.
     *
     * @type  {Object}
     */
    actions: {
        /**
         * Get all segments for the given load id.
         *
         * @param {Number} loadId
         * @return  {Promise}
         */
        async get(loadId) {
            const response = await api.segments(loadId)

            const segments = response.get('data', []).map((segment) => {
                return {
                    ...segment,
                    load_id: loadId,
                }
            })

            helperActions.add(this.$state, segments)

            const store = useLoadsStore()

            await store.setCurrentSegmentId(loadId)

            return response
        },

        /**
         * Update the given segment.
         *
         * @param {String} id
         * @param {Object} payload
         * @param {Number} loadId
         * @return {Promise}
         */
        async updateSegment(id, payload, loadId = null) {
            const loadStore = useLoadsStore()

            const response = await api.updateSegment(id, payload)

            const data = response.get('data', {})

            if (loadId) {
                await loadStore.updateCurrentTruckAndDrivers(loadId, {
                    truck: data.truck,
                    drivers: data.drivers,
                })
            }

            helperActions.add(this.$state, data)

            return response
        },
    },
})
