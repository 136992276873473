import { toArray, isObject } from '@/app/Helpers'

export const push = (state, model, id) => {
    const key = id || 'id'
    state.items[model[key]] = model

    if (!state.ids.includes(model[key])) {
        state.ids.push(model[key])
    }
}

export const unshift = (state, model) => {
    if (!state.ids.includes(model.id)) {
        state.ids.unshift(model.id)
    }
}

export const add = (state, payload, id) => {
    const models = toArray(payload)

    if (models.length > 0) {
        models.forEach((model) => push(state, model, id))
    }
}

export const addToFront = (state, payload) => {
    const models = toArray(payload)

    if (models.length > 0) {
        models.forEach((model) => unshift(state, model))
    }
}

export const remove = (state, id) => {
    if (state.ids.includes(id)) {
        delete state.items[id]

        state.ids.splice(
            state.ids.findIndex((item) => item === id),
            1
        )
    }
}

export const replace = (state, model) => {
    if (isObject(model) && state.ids.includes(model.id)) {
        state.items = {
            ...state.items,
            [model.id]: model,
        }
    }
}

export const set = (state, payload, id) => {
    const models = toArray(payload)

    if (models.length > 0) {
        state.ids = []
        state.items = {}

        models.forEach((model) => push(state, model, id))
    }
}

export const setMeta = (state, payload) => {
    if (isObject(payload) && state !== payload) {
        state.meta = {
            ...payload,
        }
    }
}

export default {
    add,
    addToFront,
    remove,
    replace,
    set,
    setMeta,
}
