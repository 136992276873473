import Bugsnag from '@bugsnag/js'
import { defineStore } from 'pinia'
import Client from 'App/Api/Client'

/**
 * The API client.
 *
 * @type {Client}
 */
const api = new Client()
/**
 * Authentication store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useAuthStore = defineStore('auth', {
    /**
     * Authentication's state.
     *
     * @return  {Object}
     */
    state: () => ({
        user: null,
    }),

    /**
     * Authentication's getters.
     *
     * @return  {Object}
     */
    getters: {
        /**
         * Check if the user is authenticated.
         *
         * @param {Object} state
         * @return {Boolean}
         */
        isAuthenticated: (state) => !!state.user,

        /**
         * Get the user's permissions.
         *
         * @param {Object} state
         * @return {Array}
         */
        permissions: (state) => state.user?.permissions || [],
    },

    /**
     * Authentication's actions.
     *
     * @return  {Object}
     */
    actions: {
        /**
         * Get all the user data.
         *
         * @return {Promise}
         */
        async get() {
            const response = await api.me()

            this.user = response.get('data', {})

            if (!import.meta.env.DEV && this.user.id) {
                Bugsnag.setUser(
                    this.user.id,
                    this.user.work?.email,
                    this.user.user_name
                )
            }

            return response
        },

        /**
         * Attempt to login the user.
         *
         * @param {String} username
         * @param {String} password
         * @return {Promise}
         */
        async login(username, password) {
            const response = await api.login(username, password)

            this.user = response.get('data', {})

            if (!import.meta.env.DEV && this.user?.id) {
                Bugsnag.setUser(
                    this.user.id,
                    this.user.work?.email,
                    this.user.user_name
                )
            }

            return response
        },
    },
})
