import Response from './Response'

/**
 * Http error response.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class ErrorResponse extends Response {
    /**
     * Create a new ErrorResponse instance.
     *
     * @param {Object|Error} error
     */
    constructor(error) {
        super(error.response || error)
    }

    /**
     * Get the errors from the response.
     *
     * @return {Object}
     */
    errors() {
        return this.get('errors', {})
    }

    /**
     * Get the errors for the given form input/field.
     *
     * @param {String} name
     * @return {Array}
     */
    getErrorsForField(name) {
        const errors = this.errors()

        return name in errors ? errors[name] : []
    }

    /**
     * Get the first error message for the given field.
     *
     * @param {String} name
     * @return {String	}
     */
    getFirstError(name) {
        const errors = this.getErrorsForField(name)

        return errors.length > 0 ? errors[0] : ''
    }

    /**
     * Get the default response type.
     *
     * @return {String}
     */
    getDefaultType() {
        return 'error'
    }

    /**
     * Determine if a response has errors.
     *
     * @return {Boolean}
     */
    hasErrors() {
        return !!this.get('errors')
    }
}
