import { defineStore } from 'pinia'
import Client from 'App/Api/Client'
import { find } from './helpers/getters'
import { TABS } from '@/constants/dispatch'
import helperActions from './helpers/actions'
import StateFactory from './helpers/StateFactory'

/**
 * The API client instance.
 *
 * @type {Client}
 */
const api = new Client()

/**
 * Load Shipments Pinia store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useLoadShipmentsStore = defineStore('load-shipments', {
    /**
     * Load shipments' state.
     *
     * @return  {Object}
     */
    state: () => StateFactory.entity({}),

    /**
     * Load shipments' getters.
     *
     * @type  {Object}
     */
    getters: {
        /**
         * Get all the shipments.
         *
         * @param {Object} state
         * @return  {Array}
         */
        shipments(state) {
            return state.ids.map((id) => state.items[id])
        },

        /**
         * Get all shipments for the given load id.
         *
         * @return {Array}
         */
        getPurchaseOrderNameForLoad() {
            return (loadId) => {
                const id = parseInt(loadId)

                return this.shipments.reduce((accu, shipment) => {
                    if (shipment.load_ids.indexOf(id) > -1) {
                        accu.push(shipment.purchase_order_name)
                    }

                    return accu
                }, [])
            }
        },

        /**
         * Filter shipments by load id.
         *
         * @return {Array}
         */
        filterByLoadId() {
            return (loadId) => {
                const id = parseInt(loadId)

                return this.shipments.filter(
                    (shipment) => shipment.load_ids.indexOf(id) > -1
                )
            }
        },

        find,
    },

    /**
     * Load shipments' actions.
     *
     * @type  {Object}
     */
    actions: {
        /**
         * Get all shipments for the given load id.
         *
         * @param {Number} loadId
         * @return  {Promise}
         */
        async get(loadId) {
            const response = await api.shipments(loadId)

            const shipments = response.get('data', []).map((shipment) => {
                return {
                    load_id: loadId,
                    ...shipment,
                }
            })

            helperActions.add(this.$state, shipments)

            return response
        },

        /**
         * Get all shipments for the given load statuses.
         *
         * @param {Array} statuses
         * @return  {Promise}
         */
        async getShipmentsByLoadStatuses(statuses = []) {
            statuses = [].concat(statuses || [])

            const promises = statuses.map((status) => api.loadShipments(status))

            const responses = await Promise.all(promises)

            const shipments = responses.reduce((accu, response) => {
                accu = [...accu, ...response.get('data', [])]

                return accu
            }, [])

            helperActions.add(this.$state, shipments)

            return responses
        },

        /**
         * Get all shipments for the given load id.
         *
         * @return  {Promise}
         */
        async getAll() {
            const promises = TABS.map((name) => {
                return api.loadShipments(name)
            })

            const responses = await Promise.all(promises)

            responses.forEach((response) => {
                helperActions.add(this.$state, response.get('data', []))
            })

            return responses
        },
    },
})
