<template>
    <legacy-ux-banner />
    <nav-menu />
    <v-container class="container-main-content">
        <slot></slot>
    </v-container>
</template>

<script>
import { useRoute } from 'vue-router'
import NavMenu from '@/components/NavMenu.vue'
import LegacyUxBanner from '@/components/LegacyUxBanner.vue'

export default {
    /**
     * The component's registered child components.
     *
     * @type {Object}
     */
    components: {
        NavMenu,
        LegacyUxBanner,
    },

    /**
     * The component's name used for debugging.
     *
     * @type {String}
     */
    name: 'App',

    /**
     * The component's mounted lifecycle hook.
     *
     * @return {void}
     */
    mounted() {
        window.app = this
    },

    /**
     * The composition API setup.
     *
     * @return  {Object}
     */
    setup() {
        const route = useRoute()

        return {
            route,
        }
    },
}
</script>

<style lang="scss">
@import '@/sass/app.scss';
</style>
