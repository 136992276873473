/**
 * Http status code manager.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class Status {
    /**
     * Get the HTTP "accepted" status code.
     *
     * @return {Number}
     */
    static get ACCEPTED() {
        return 202
    }

    /**
     * Get the HTTP "bad request" status code.
     *
     * @return {Number}
     */
    static get BAD_REQUEST() {
        return 400
    }

    /**
     * Get the HTTP "created" status code.
     *
     * @return {Number}
     */
    static get CREATED() {
        return 201
    }

    /**
     * Get the HTTP authorization forbidden status code.
     *
     * @return {Number}
     */
    static get FORBIDDEN() {
        return 403
    }

    /**
     * Get the HTTP "not found" status code.
     *
     * @return {Number}
     */
    static get NOT_FOUND() {
        return 404
    }

    /**
     * Get the HTTP "offline" status code.
     *
     * @return {Number}
     */
    static get OFFLINE() {
        return 503
    }

    /**
     * Get the HTTP "server error" status code.
     *
     * @return {Number}
     */
    static get SERVER_ERROR() {
        return 500
    }

    /**
     * Get the HTTP "success" status code.
     *
     * @return {Number}
     */
    static get SUCCESS() {
        return 200
    }

    /**
     * Get the HTTP "too many requests" status code.
     *
     * @return {Number}
     */
    static get TOO_MANY_REQUESTS() {
        return 429
    }

    /**
     * Get the HTTP "unauthorized" status code.
     *
     * @return {Number}
     */
    static get UNAUTHORIZED() {
        return 401
    }

    /**
     * Get the HTTP "unathenticated" (unauthorized) status code.
     *
     * @return {Number}
     */
    static get UNAUTHENTICATED() {
        return this.UNAUTHORIZED
    }

    /**
     * Get the HTTP "validation errors" status code.
     *
     * @return {Number}
     */
    static get VALIDATION_ERROR() {
        return 422
    }
}
