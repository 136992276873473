export const any = (state) => state.ids.length > 0

export const count = (state) => state.ids.length

export const find = (state) => {
    return (id) => {
        if (id && state.ids.indexOf(id) > -1) {
            return state.items[id]
        }

        return null
    }
}

export const mapToArray = (state) => {
    return state.ids.map((id) => state.items[id])
}
