export default class StateFactory {
    static entity(state = {}) {
        return {
            ...state,
            ids: [],
            items: {},
        }
    }

    static getFromStorage(key, defaultValue = {}) {
        const data = localStorage.getItem(key)

        if (data) {
            return JSON.parse(data)
        }

        return defaultValue
    }

    static paginatedEntity(state = {}) {
        return this.entity({
            ...state,
            meta: this.getMetaState(),
        })
    }

    static getMetaState() {
        return {
            current_page: 0,
            from: 0,
            last_page: 1,
            per_page: 50,
            to: 0,
            total: 0,
        }
    }
}
