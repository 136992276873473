<template>
    <component :is="layout">
        <component v-if="hasMatchedRoute" :is="active.component" />
    </component>
</template>

<script>
import boltRouter from '@/router/bolt'
import BoltRouterNav from './BoltRouterNav.vue'
import Auth from '@/layouts/Auth.vue'
import Dashboard from '@/layouts/Dashboard.vue'

export default {
    /**
     * The component's registered child components.
     *
     * @type {Object}
     */
    components: {
        Auth,
        BoltRouterNav,
        Dashboard,
        ...boltRouter.components,
    },

    /**
     * The component's computed properties.
     *
     * @type {Object}
     */
    computed: {
        /**
         * Determine if a Bolt route has been matched.
         *
         * @return {boolean}
         */
        hasMatchedRoute() {
            return !!this.active.component
        },

        /**
         * Determine if the current route/view requires authentication
         *
         * @return {Boolean}
         */
        isAuthenticatedRoute() {
            if (!this.hasMatchedRoute) {
                return false
            }

            return this.active.module !== 'authentication'
        },

        /**
         * Get the layout component determined by authentication / route status.
         *
         * @return {VueComponent}
         */
        layout() {
            if (this.isAuthenticatedRoute) {
                return Dashboard
            }

            return Auth
        },
    },

    /**
     * The component's local methods.
     *
     * @type {Object}
     */
    methods: {
        /**
         * Resolve the URL query params to a Bolt route.
         *
         * @return {void}
         */
        resolveBoltRoute() {
            const params = this.$route.query

            const matched = boltRouter.routes.find((route) => {
                if (route.package && params.package) {
                    return (
                        route.module.toLowerCase() ===
                            params.module.toLowerCase() &&
                        route.template.toLowerCase() ===
                            params.template.toLowerCase() &&
                        route.package.toLowerCase() ===
                            params.package.toLowerCase()
                    )
                }

                return (
                    route.module.toLowerCase() ===
                        params.module.toLowerCase() &&
                    route.template.toLowerCase() ===
                        params.template.toLowerCase()
                )
            })

            return (this.active = matched || {})
        },
    },

    /**
     * The component's name used for debugging.
     *
     * @type {String}
     */
    name: 'BoltRouter',

    /**
     * Get the component's initial state.
     *
     * @return {Object}
     */
    data() {
        return {
            active: {},
        }
    },

    /**
     * The component's mounted lifecycle hook.
     *
     * @return {void}
     */
    mounted() {
        this.resolveBoltRoute()

        window.bolt = {
            router: this,
            ...(window.bolt || {}),
        }
    },
}
</script>
<style scoped></style>
