import { orderBy } from 'lodash'
import Client from 'App/Api/Client'
import { defineStore } from 'pinia'
import Driver from 'App/Models/Driver'
import { find } from './helpers/getters'
import helperActions from './helpers/actions'
import StateFactory from './helpers/StateFactory'

/**
 * The API client.
 *
 * @type {Client}
 */
const api = new Client()

/**
 * Driver's Pinia store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useDriverStore = defineStore('drivers', {
    /**
     * Driver's state.
     *
     * @return  {Object}
     */
    state: () =>
        StateFactory.paginatedEntity({
            active: null,
        }),

    /**
     * Driver's getters.
     *
     * @type  {Object}
     */
    getters: {
        find,
        /**
         * Get the active driver.
         *
         * @param {Object} state
         *
         * @return {Object}
         */
        activeDriver(state) {
            return new Driver(state.items[state.active])
        },

        /**
         * Get the drivers.
         *
         * @param {Object} state
         * @return {Array}
         */
        drivers(state) {
            return orderBy(
                state.ids.map((id) => new Driver(state.items[id])),
                'last_name'
            )
        },

        /**
         * Get all drivers from the given array of ids.
         *
         * @param {Object} state
         * @returns {Function}
         */
        getDriversById(state) {
            return (ids) => {
                const drivers = [].concat(ids).reduce((accu, id) => {
                    if (!state.items[id]) {
                        return accu
                    }

                    accu.push(new Driver(state.items[id]))

                    return accu
                }, [])

                return orderBy(drivers, 'last_name')
            }
        },
    },

    /**
     * Driver's actions.
     *
     * @type {Object}
     */
    actions: {
        /**
         * Fetch a driver.
         *
         * @param {Number} id
         * @return {Object}
         */
        async fetchDriver(id) {
            const response = await api.fetchDriver(id)

            helperActions.add(this.$state, response.get('data', {}))

            return response
        },

        /**
         * Get the drivers from the API.
         *
         * @return {Object}
         */
        async get() {
            const response = await api.drivers()

            helperActions.add(this.$state, response.get('data', []))

            return response
        },

        /**
         * Send an email to the driver.
         *
         * @param {Number} segmentId
         * @param {Array} drivers
         * @return {void}
         */
        async sendEmail(segmentId, drivers) {
            return await api.emailDrivers(segmentId, drivers)
        },

        /**
         * Set the active driver.
         *
         * @param {Number} id
         * @return {void}
         */
        setActive(id) {
            this.active = id
        },

        /**
         * Update a driver.
         *
         * @return {void}
         */
        async updateDriver(payload) {
            const response = await api.updateDriver(payload)

            helperActions.add(this.$state, response.get('data', []))

            return response
        },
    },
})
