import Status from 'App/Http/Status'

/*
|--------------------------------------------------------------------------
| Axios interceptors
|--------------------------------------------------------------------------
|
| Global Axios interceptors that are run before each request is sent and
| after each response is received.
|
*/

/**
 * The API endpoints (w/out base url - api/v1) that should not have
 * an Authorization header & bearer token attached to the request.
 *
 * @type {Array}
 */
const excluded = [
    // 'login'
]

/**
 * Axios global request interceptors.
 *
 * @type {Array}
 */
const request = [
    /**
     * Example request interceptor.
     *
     * @param {Object} config
     * @return {Object}
     */
    // config => {
    //     return config
    // },
]

/**
 * Handle an unauthenticated error response  from the API.
 *
 @return {void}
 */
const unauthenticatedErrorHandler = () => {
    const isDevEnvironment = import.meta.env.DEV

    if (!isDevEnvironment) {
        window.location = '/'
    }

    console.warn(
        'Received 401 unauthenticated response -- redirect will be triggered in non-dev environment.'
    )
}

/**
 * Axios global response interceptors.
 *
 * @type {Array}
 */
const response = [
    {
        /**
         * Success response interceptor.
         *
         * @param {Object} response
         * @return {Object}
         */
        success: (response) => {
            return response
        },

        /**
         * Error response interceptor.
         *
         * @param {Error} error
         * @return {Promise}
         */
        error: (error) => {
            const params = new URLSearchParams(window.location.search)

            const template = params.get('template')

            if (
                error.response.status === Status.UNAUTHENTICATED &&
                template !== 'frmLogin'
            ) {
                return unauthenticatedErrorHandler()
            }

            return Promise.reject(error)
        },
    },
]

export default {
    request,
    response,
}
