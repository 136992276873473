/**
 * Base start-up engine task.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class Task {
    /**
     * Create a new Task instance.
     *
     * @param {Vue} app
     */
    constructor(app) {
        /**
         * The Vue application instance.
         *
         * @type {Vue}
         */
        this.app = app
    }

    /**
     * Run the asynchronous task and resolve when complete.
     *
     * @param {Function} next
     * @return {void}
     */
    async run() {
        throw new Error(
            `The App/Start/Tasks/Task::run method must be implemented.`
        )
    }

    /**
     * Determine if the environment is a production environment.
     *
     * @return {Boolean}
     */
    isProduction() {
        return !import.meta.env.DEV
    }
}
