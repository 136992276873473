import Task from './Task'
import { env } from 'App/Helpers'
import { LicenseManager } from 'ag-grid-enterprise'

/**
 * Configure the Ag-Grid library.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class ConfigureAgGrid extends Task {
    /**
     * Run the asynchronous task and resolve when complete.
     *
     * @param {Function} next
     * @return {void}
     */
    async run(next) {
        const key = env('AG_GRID_LICENSE_KEY')

        if (key) {
            LicenseManager.setLicenseKey(key)
        }

        next()
    }
}
