<script setup>
import Client from 'App/Api/Client'

const api = new Client()

const onClick = async () => {
    try {
        await api.legacyUx()

        location.reload()
    } catch (e) {
        console.error('Legacy UX is only available in live environments', e)
    }
}
</script>
<template>
    <div
        class="message-bar flex justify-between align-center px-5 py-1"
        style="
            font-size: 12px;
            color: #000;
            background-color: #f9f95d;
            font-weight: 500;
        "
    >
        <div class="message-bar-left align-left">
            Welcome to our updated UX.
            <a
                href="https://www.boltsystem.com/new-ux/"
                target="_new"
                style="color: #000; border-bottom: 1px solid #000"
            >
                Learn more.
            </a>
        </div>
        <button
            class="message-bar-right toggle-to-legacy-ux flex align-center"
            style="cursor: pointer"
            @click.prevent="onClick"
            href="/index.cfm?module=dispatch&template=dspDispatch&showlegacy="
        >
            Switch to legacy UX
        </button>
    </div>
</template>
