import Task from './Task'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

/**
 * Install and setup Bugsnag.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class SetupBugsnag extends Task {
    /**
     * Run the asynchronous task and resolve when complete.
     *
     * @param {Function} next
     * @return {void}
     */
    async run(next) {
        if (!this.shouldStartBugsnag()) {
            return next()
        }

        Bugsnag.start({
            apiKey: import.meta.env.VITE_BUGSNAG_KEY,
            plugins: [new BugsnagPluginVue()],
            context: window.location.hostname,
        })

        BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_KEY })

        const bugsnagVue = Bugsnag.getPlugin('vue')

        this.app.use(bugsnagVue)

        next()
    }

    /**
     * Determine if Bugsnag should start monitoring error reports.
     *
     * @return {Boolean}
     */
    shouldStartBugsnag() {
        return this.isProduction() && !!import.meta.env.VITE_BUGSNAG_KEY
    }
}
