import { defineAsyncComponent } from 'vue'

const Assets = defineAsyncComponent(() => import('@/views/assets/Index.vue'))

const Dashboard = defineAsyncComponent(() =>
    import('@/views/dashboard/Index.vue')
)

const Login = defineAsyncComponent(() => import('@/views/auth/Login.vue'))

const Calendar = defineAsyncComponent(() =>
    import('@/views/dispatch/calendar/Index.vue')
)

const Dispatch = defineAsyncComponent(() =>
    import('@/views/dispatch/Index.vue')
)

const Employees = defineAsyncComponent(() =>
    import('@/views/employees/Index.vue')
)

const Locations = defineAsyncComponent(() =>
    import('@/views/locations/Index.vue')
)

const Reports = defineAsyncComponent(() => import('@/views/reports/Index.vue'))

const Settings = defineAsyncComponent(() =>
    import('@/views/settings/Index.vue')
)

const Terminals = defineAsyncComponent(() =>
    import('@/views/terminals/Index.vue')
)

const TruckOwners = defineAsyncComponent(() =>
    import('@/views/truckOwners/Index.vue')
)

const Vendors = defineAsyncComponent(() => import('@/views/vendors/Index.vue'))

const Profile = defineAsyncComponent(() => import('@/views/profile/Index.vue'))

/**
 * The custom Bolt component/route map.
 *
 * @type {object}
 */
export default {
    components: {
        Assets,
        Calendar,
        Dashboard,
        Dispatch,
        Employees,
        Locations,
        Login,
        Profile,
        Reports,
        Settings,
        Terminals,
        TruckOwners,
        Vendors,
    },
    routes: [
        {
            module: 'authentication',
            template: 'frmLogin',
            package: '',
            component: 'Login',
        },
        {
            module: 'dashboard',
            template: '',
            package: '',
            component: 'Dashboard',
        },
        {
            module: 'operations',
            template: 'dspModifyAsset',
            package: 'asset',
            component: 'Assets',
        },
        {
            module: 'accounting',
            template: 'dspEmployee',
            package: 'employee',
            component: 'Employees',
        },
        {
            module: 'accounting',
            template: 'dspCustomer',
            package: '',
            component: 'Locations',
        },
        {
            module: 'dispatch',
            template: 'dspDispatch',
            package: '',
            component: 'Dispatch',
        },
        {
            module: 'management',
            template: 'dspTerminal',
            package: 'boltAdmin',
            component: 'Terminals',
        },
        {
            module: 'operations',
            template: 'dspModifyTruckOwner',
            package: 'asset',
            component: 'TruckOwners',
        },
        {
            module: 'dispatch',
            template: 'dspDispatchTimeline',
            package: 'calendar',
            component: 'Calendar',
        },
        {
            module: 'accounting',
            template: 'dspVendor',
            package: '',
            component: 'Vendors',
        },
        {
            module: 'reports',
            template: '',
            package: '',
            component: 'Reports',
        },
        {
            module: 'settings',
            template: '',
            package: '',
            component: 'Settings',
        },
        {
            module: 'user',
            template: 'profile',
            package: '',
            component: 'Profile',
        },
    ],
}
