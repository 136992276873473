import App from './App.vue'
import router from './router'
import { createApp } from 'vue'
import './assets/css/tailwind.css'
import { createPinia } from 'pinia'
import Engine from 'App/Foundation/Engine'
import VueApexCharts from 'vue3-apexcharts'
import { useVuetify } from '@/composables/useVuetify'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)

const { vuetify } = useVuetify()

app.use(createPinia())

app.use(vuetify)

app.use(VueGoogleMaps, {
    load: {
        key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
    },
})

app.use(VueApexCharts)

const engine = new Engine()

engine.start(app).then(async () => {
    app.use(router)

    await router.isReady()

    app.mount('#app')
})
