<script setup>
import Client from 'App/Api/Client'
import NavMenuItem from './NavMenuItem.vue'
import UserActions from './UserActions.vue'
import { useAuthStore } from '@/stores/Auth'
import { useUiStore } from '@/stores/UiStore'
import { ref, onMounted, computed } from 'vue'
import logoUrl from '../assets/img/bolt-logo-new-primary-icon.png'

const api = new Client()

/**
 * The computed refs for the component.urn description]
 */
const drawer = ref(false)

/**
 * The store instances.
 */
const uiStore = useUiStore()
const authStore = useAuthStore()

const menu = computed(() => uiStore.menu)

/**
 * The user computed property.
 *
 * @type {Object}
 */
const user = computed(() => authStore.$state.user)

/**
 * The logout event handler.
 *
 * @return {void}
 */
const onLogout = async () => {
    try {
        const response = await api.logout()

        console.log(response)
    } catch (e) {
        console.error(e)
    } finally {
        window.location = '/'
    }
}

/**
 * The component's onMounted lifecycle hook.
 *
 * @return {void}
 */
onMounted(async () => {
    try {
        await uiStore.fetchNavbar()
    } catch (e) {
        console.error(e)
    }
})
</script>

<template>
    <v-toolbar density="default" dark="true" color="black" class="px-5">
        <div
            class="flex items-center justify-between md:justify-between w-full"
        >
            <div class="flex items-center">
                <a href="/index.cfm?module=dashboard&template=&package=">
                    <v-img
                        class="me-3"
                        max-height="100%"
                        width="40px"
                        :aspect-ratio="1"
                        :src="logoUrl"
                    ></v-img>
                </a>
                <v-app-bar-nav-icon @click="drawer = true" class="md:hidden" />

                <div class="md:flex md:items-center hidden md:visible">
                    <v-menu
                        v-for="item in menu"
                        :key="item.id"
                        :close-on-content-click="false"
                        open-delay="100"
                        close-delay="100"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-if="item?.children?.length"
                                v-bind="props"
                                density="default"
                                class="capitalize px-3 font-weight-bold"
                            >
                                {{ item.name }}
                                <v-icon
                                    icon="fas fa-chevron-down"
                                    size="x-small"
                                    class="ms-1"
                                ></v-icon>
                            </v-btn>
                            <v-btn
                                v-else
                                v-bind="props"
                                density="default"
                                class="px-3 font-weight-bold"
                                :href="item.url"
                            >
                                <span v-if="item.name">
                                    {{ item.name }}
                                </span>
                                <v-icon
                                    v-if="item.icon"
                                    :icon="item.icon"
                                    class="ms-1"
                                ></v-icon>
                            </v-btn>
                        </template>
                        <v-list v-if="item.children.length">
                            <nav-menu-item :group="item.children" />
                        </v-list>
                    </v-menu>
                </div>
            </div>
            <div class="flex items-center">
                <user-actions :user="user" :onLogout="onLogout" />
            </div>
        </div>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" absolute location="left" temporary>
        <div class="flex flex-col items-start w-full">
            <div
                class="w-full flex items-center pl-3 py-2 justify-between border-b"
            >
                <h3 class="font-bold">Bolt System</h3>
                <v-btn
                    variant="plain"
                    size="x-small"
                    icon
                    @click="drawer = false"
                >
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </div>
            <v-menu
                v-for="item in menu"
                :key="item.id"
                location="bottom"
                :close-on-content-click="false"
                open-delay="100"
                close-delay="100"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        v-bind="props"
                        density="default"
                        class="capitalize px-3 font-weight-bold flex justify-between w-full"
                        variant="plain"
                        :append-icon="
                            item.children.length ? 'fas fa-chevron-down' : ''
                        "
                    >
                        {{ item.name }}
                    </v-btn>
                </template>
                <v-list v-if="item.children.length">
                    <nav-menu-item :group="item.children" />
                </v-list>
            </v-menu>
        </div>
    </v-navigation-drawer>
</template>
