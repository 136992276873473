/**
 * Get an environment variable's value by key.
 *
 * @param  {String} key
 * @param  {*} defaultValue
 * @return {mixed}
 */
export const env = (key, defaultValue = null) => {
    if (key.indexOf('VITE') === -1) {
        key = `VITE_${key}`
    }

    return import.meta.env[key] || defaultValue
}

/**
 * Test whether the given item is a valid object.
 *
 * @param {*} item
 * @return {Boolean}
 */
export const isObject = (item) => typeof item === 'object' && item !== null

/**
 * Save the given data to local storage.
 *
 * @param  {String} key
 * @param  {*} data
 * @return {*}
 */
export const saveToStorage = (key, data) => {
    const value = data

    localStorage.setItem(key, JSON.stringify(value))

    return data
}

/**
 * Cast the given item to an array.
 *
 * @param {*} item
 * @return {Array}
 */
export const toArray = (item) => {
    if (Array.isArray(item)) {
        return item
    }

    if (item) {
        return [].concat(item)
    }

    return []
}
