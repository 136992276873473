import AuthCheck from './Tasks/AuthCheck'
import SetupBugsnag from './Tasks/SetupBugsnag'
import ConfigureAxios from './Tasks/ConfigureAxios'
import ConfigureAgGrid from './Tasks/ConfigureAgGrid'
import InstallVuePlugins from './Tasks/InstallVuePlugins'

/**
 * The application's bootstrapping engine.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class Engine {
    async start(app) {
        await this._promise(new SetupBugsnag(app))
        await this._promise(new InstallVuePlugins(app))
        await this._promise(new ConfigureAgGrid())
        await this._promise(new ConfigureAxios())
        await this._promise(new AuthCheck())

        return app
    }

    /**
     * Create a new start-up task promise.
     *
     * @param {Task} task
     * @return {Promise}
     */
    _promise(task) {
        return new Promise((resolve) => task.run(resolve))
    }
}
