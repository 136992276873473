import { axios } from './'
import ErrorResponse from './Responses/ErrorResponse'
import SuccessResponse from './Responses/SuccessResponse'

/**
 * Http request (axios wrapper).
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class Request {
    /**
     * Create a new Request instance.
     *
     * @param {Axios} http
     */
    constructor(http) {
        /**
         * The HTTP request adapter client (axios instance).
         *
         * @type {Axios}
         */
        this.http = http || axios

        /**
         * The current request's data to send.
         *
         * @type {Object}
         */
        this.data = {}

        /**
         * The HTTP request method.
         *
         * @type {String}
         */
        this.method = 'get'

        /**
         * The additional HTTP request options.
         *
         * @type {Object}
         */
        this.options = {}

        /**
         * The API endpoint path for the current request.
         *
         * @type {String}
         */
        this.url = ''
    }

    /**
     * Make a HTTP "DELETE" request to the API.
     *
     * @param {String} url
     * @param {Object} options
     * @return {Promise}
     */
    delete(url, options = {}) {
        return this.request('delete', url, options).send()
    }

    /**
     * Handle an API error response & create a new ErrorResponse instance.
     *
     * @param {Error} error
     * @return {Promise}
     */
    error(error) {
        return Promise.reject(new ErrorResponse(error))
    }

    /**
     * Make a HTTP "GET" request to the API.
     *
     * @param {String} url
     * @param {Object} options
     * @return {Promise}
     */
    get(url, options = {}) {
        return this.request('get', url, options).send()
    }

    /**
     * Make a HTTP "PATCH" request to the API.
     *
     * @param {String} url
     * @param {Object} data
     * @param {Object} options
     * @return {Promise}
     */
    patch(url, data, options = {}) {
        return this.request('patch', url, data, options).send()
    }

    /**
     * Make a HTTP "POST" request to the API.
     *
     * @param {String} url
     * @param {Object} data
     * @param {Object} options
     * @return {Promise}
     */
    post(url, data, options = {}) {
        return this.request('post', url, data, options).send()
    }

    /**
     * Make a HTTP "PUT" request to the API.
     *
     * @param {String} url
     * @param {Object} data
     * @param {Object} options
     * @return {Promise}
     */
    put(url, data, options = {}) {
        return this.request('put', url, data, options).send()
    }

    /**
     * Prepare to send a new request with the given parameters.
     *
     * @param {String} method
     * @param {String} url
     * @param {?Object} data
     * @return {Request}
     */
    request(method, url, data = null, options = {}) {
        this.url = url
        this.method = method
        this.data = data || {}
        this.options = options || {}

        return this
    }

    /**
     * Reset the request properties to their default values.
     *
     * @return {Request}
     */
    reset() {
        return this.request('get', '', {})
    }

    /**
     * Send the request to the API that resolves a SuccessResponse or ErrorResponse instance.
     *
     * @return {Promise}
     */
    async send() {
        try {
            const response = await this._send()

            this.reset()

            return this.success(response)
        } catch (error) {
            this.reset()

            return this.error(error)
        }
    }

    /**
     * Handle a successful API response and create a new SuccessResponse instance.
     *
     * @param {Response} response
     * @return {Promise}
     */
    success(response) {
        return Promise.resolve(new SuccessResponse(response))
    }

    /**
     * Create and send a new request to the API.
     *
     * @return {Promise}
     */
    _send() {
        return this.http[this.method](this.url, this.data, this.options)
    }
}
