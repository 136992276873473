<template>
    <v-btn
        variant="tonal"
        id="main-menu-activator"
        append-icon="fal fa-chevron-down"
    >
        Nav Links
    </v-btn>
    <v-menu activator="#main-menu-activator">
        <v-list>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'operations',
                            package: 'asset',
                            template: 'dspModifyAsset',
                        },
                    }"
                >
                    Assets
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'accounting',
                            package: 'employee',
                            template: 'dspEmployee',
                        },
                    }"
                >
                    Employees
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'accounting',
                            package: '',
                            template: 'dspCustomer',
                        },
                    }"
                >
                    Locations
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'dispatch',
                            package: '',
                            template: 'dspDispatch',
                        },
                    }"
                >
                    Dispatch
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'reports',
                            package: '',
                            template: '',
                        },
                    }"
                >
                    Reports
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'settings',
                            package: '',
                            template: '',
                        },
                    }"
                >
                    Settings
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'management',
                            package: '',
                            template: 'dspTerminal',
                        },
                    }"
                >
                    Terminals
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'operations',
                            package: 'asset',
                            template: 'dspModifyTruckOwner',
                        },
                    }"
                >
                    Truck Owners
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'accounting',
                            package: '',
                            template: 'dspVendor',
                        },
                    }"
                >
                    Vendors
                </router-link>
            </v-list-item>
            <v-list-item>
                <router-link
                    :to="{
                        name: 'root',
                        query: {
                            module: 'dashboard',
                            package: '',
                            template: '',
                        },
                    }"
                >
                    Dashboard
                </router-link>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
