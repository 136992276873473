import Client from 'App/Api/Client'
import { defineStore } from 'pinia'
import { find } from './helpers/getters'
import helperActions from './helpers/actions'
import StateFactory from './helpers/StateFactory'

/**
 * The API client instance.
 *
 * @type  {Client}
 */
const api = new Client()

/**
 * The Terminal store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useTerminalStore = defineStore('terminals', {
    /**
     * The terminal's state.
     *
     * @return {Object}
     */
    state: () => StateFactory.paginatedEntity(),
    getters: {
        find,

        /**
         * Get all terminals in the store.
         *
         * @param {Object} state
         * @return {Array}
         */
        terminals(state) {
            return state.ids.map((id) => state.items[id])
        },
    },

    /**
     * The terminal's actions.
     *
     * @type {Object}
     */
    actions: {
        /**
         * Fetch a terminal by id.
         *
         * @param {Number} id
         * @return {Promise}
         */
        async fetchTerminal(id) {
            const response = await api.fetchTerminal(id)

            helperActions.add(this.$state, response.get('data', {}))

            return response
        },

        /**
         * Get all the terminals.
         *
         * @return {Promise}
         */
        async get() {
            const response = await api.terminals()

            helperActions.add(this.$state, response.get('data', []))

            return response
        },
    },
})
