import { defineStore } from 'pinia'
import { useLoadsStore } from './Loads'
import {
    DELETE_LOAD,
    SEND_ELD,
    EMAIL_DRIVER,
    UPDATE_TRUCK,
    UPDATE_DRIVER,
    UPDATE_STATUS,
    UPDATE_TRUCK_LOCATION,
    VIEW_LOAD,
} from '@/constants/dispatch'
import Client from 'App/Api/Client'
import defaultMenu from '@/config/navbar'
import { CALENDAR_SEGMENT_REF_ID } from '@/constants/calendar'

/**
 * The API client instance.
 *
 * @type  {Client}
 */
const api = new Client()

/**
 * Generate the menu structure
 *
 * @param {Array} nav
 * @param {[type]} key
 *
 * @return  {Object}
 */
const generateMenu = (nav, key) => {
    return nav[key].reduce(
        (accu, item) => {
            if (!item.category) {
                accu.children.push({ ...item, children: [] })

                return accu
            }

            let parent = accu.children.find(
                (heading) => heading.id === item.category
            )

            if (!parent) {
                let name = item.category

                parent = {
                    category: null,
                    description: null,
                    id: item.category,
                    name: name,
                    url: null,
                    children: [],
                }

                accu.children.push(parent)
            }

            parent.children.push(item)

            return accu
        },
        {
            name: key,
            icon: '',
            id: key,
            children: [],
        }
    )
}

/**
 * Convert the item to a menu item.
 *
 * @param {Object} item
 * @return {Object}
 */
const toMenuItem = (item) => {
    return {
        id: item.id,
        name: item.name,
        icon: item.icon,
        url: item.url,
        visible: true,
        children: [],
    }
}

/**
 * The UI store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useUiStore = defineStore('ui', {
    /**
     * The UI store's state.
     *
     * @return {Object}
     */
    state: () => {
        return {
            calendar: {
                calendarSegmentRefId:
                    localStorage.getItem(CALENDAR_SEGMENT_REF_ID) ||
                    'purchase-orders',
            },
            menu: [],
            navbar: {},
            popups: {
                [DELETE_LOAD]: {
                    id: null,
                    open: false,
                    segmentId: null,
                },

                [EMAIL_DRIVER]: {
                    id: null,
                    open: false,
                    segmentId: null,
                },

                [SEND_ELD]: {
                    id: null,
                    open: false,
                    segmentId: null,
                },

                [UPDATE_DRIVER]: {
                    id: null,
                    open: false,
                    segmentId: null,
                },

                [UPDATE_STATUS]: {
                    id: null,
                    open: false,
                    segmentId: null,
                },

                [UPDATE_TRUCK]: {
                    id: null,
                    open: false,
                    segmentId: null,
                },

                [UPDATE_TRUCK_LOCATION]: {
                    id: null,
                    open: false,
                    segmentId: null,
                },

                [VIEW_LOAD]: {
                    id: null,
                    open: false,
                    tab: 'stops',
                },
            },
        }
    },

    /**
     * The load actions stack's getters.
     *
     * @type {Object}
     */
    getters: {
        /**
         * Get the active load drawer tab.
         *
         * @param {Object} state
         * @return {String}
         */
        activeLoadDrawerTab(state) {
            return state.popups[VIEW_LOAD].tab || null
        },

        /**
         * Get the loads for the given action popup.
         *
         * @param  {Object} state
         * @return {Array}
         */
        actionLoads(state) {
            const loadStore = useLoadsStore()

            return (name) => {
                const id = state.popups[name].id

                if (!Array.isArray(id)) {
                    return loadStore.items[id]
                }

                return id.map((identifier) => loadStore.items[identifier])
            }
        },

        /**
         * Get the action's segment id.
         *
         * @return {Function}
         */
        actionSegmentId() {
            return (name) => this.popups[name].segmentId
        },

        /**
         * Determine if the given action is active.
         *
         * @return {Boolean}
         */
        isActionActive() {
            return (name) => this.popups[name].open
        },

        /**
         * Get the navbar menu.
         *
         * @param {Object} state
         * @return {Array}
         */
        flatMenu(state) {
            return state.navbar.menu
        },

        /**
         * Get the navbar other menu items.
         *
         * @param {Object} state
         * @return {Array}
         */
        otherMenuItems(state) {
            return state.navbar.others
        },

        /**
         * Get the navbar reports.
         *
         * @param {Object} state
         * @return {Array}
         */
        reports(state) {
            return state.navbar.reports
        },

        /**
         * Get the navbar settings.
         *
         * @param {Object} state
         * @return {Array}
         */
        settings(state) {
            return state.navbar.settings
        },
    },

    /**
     * The popup stack actions.
     *
     * @type {Object}
     */
    actions: {
        /**
         * Close the given action.
         *
         * @param {String} name
         * @return {void}
         */
        closeAction(name) {
            this.popups[name] = {
                ...this.popups[name],
                open: false,
            }
        },

        /**
         * Fetch the navbar data.
         *
         * @return {Promise}
         */
        async fetchNavbar() {
            this.menu = []
            this.navbar = {}

            const response = await api.nav()

            this.navbar = response.get('data', {})

            this.menu.push({
                name: 'Dashboard',
                icon: '',
                id: 'dashboard',
                url: '/index.cfm?module=dashboard&template=&package=',
                children: [],
            })

            defaultMenu.forEach((group) => {
                this.menu.push(generateMenu(this.navbar, group))
            })

            this.menu.push({
                name: 'Reports',
                icon: '',
                id: 'reports',
                url: '/index.cfm?module=reports&template=&package=',
                children: [],
            })

            this.menu.push({
                name: 'Other',
                icon: '',
                id: 'other',
                children: this.navbar.other.map(toMenuItem),
            })

            return response
        },

        /**
         * Open the given action.
         *
         * @param {String} name
         * @param {Object} data
         * @return {void}
         */
        openAction(name, data) {
            this.popups[name] = {
                ...this.popups[name],
                ...data,
                open: true,
            }
        },

        /**
         * Set the action's segment id.
         *
         * @param {String} name
         * @param {Number} segmentId
         * @return {void}
         */
        setActionSegmentId(name, segmentId) {
            this.popups[name].segmentId = segmentId
        },

        /**
         * Set the displayable segment type.
         *
         * @param {String} type
         * @return {void}
         */
        setCalendarSegmentRefId(type) {
            this.calendar.calendarSegmentRefId = type
        },

        /**
         * Set the active load drawer tab.
         *
         * @param {String} tab
         * @return {void}
         */
        setLoadDrawerTab(tab) {
            this.popups[VIEW_LOAD].tab = tab
        },
    },
})
