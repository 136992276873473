import { defineStore } from 'pinia'

/**
 * Toast state store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useToastStore = defineStore('toast', {
    /**
     * Toast's state.
     *
     * @return  {Object}
     */
    state: () => ({
        isLoading: false,
        message: '',
        type: 'info',
    }),

    /**
     * Toast's getters.
     *
     * @return  {Object}
     */
    getters: {
        /**
         * Check if the toast is visible.
         *
         * @param {Object} state
         * @return {Boolean}
         */
        isVisible: (state) => !!state.message,
    },

    /**
     * Toast's actions.
     *
     * @return  {Object}
     */
    actions: {
        /**
         * Set the toast message.
         *
         * @param {String} message
         * @return {void}
         */
        setMessage(message) {
            this.message = message
        },

        /**
         * Set the toast message.
         *
         * @param {String} options.message
         * @param {String} options.type
         * @return {void}
         */
        setNotification({ message, type, isLoading = false }) {
            const FOUR_SECONDS = 4000

            this.isLoading = isLoading
            this.message = message
            this.type = type

            setTimeout(() => {
                this.$reset()
            }, FOUR_SECONDS)
        },
    },
})
