<script setup>
import { ref } from 'vue'
import logoUrl from '@/assets/img/bolt-logo-new-primary-icon.png'

/**
 * The local refs.
 */
const year = ref(new Date().getFullYear())

/**
 * The Options API.
 */
defineOptions({
    /**
     * The component's name used for debugging.
     *
     * @type {String}
     */
    name: 'AuthLayout',
})
</script>

<template>
    <v-container class="container-main-content">
        <div class="bg-gray-100">
            <div
                class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
            >
                <div
                    class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0"
                >
                    <div
                        class="flex p-6 pb-4 items-center text-2xl font-semibold text-gray-900 dark:text-white"
                    >
                        <img
                            class="m-auto"
                            width="100"
                            :src="logoUrl"
                            alt="Bolt Logo"
                        />
                    </div>
                    <slot></slot>
                </div>
                <div
                    class="flex flex-col sm:max-w-md text-gray-500 mt-4 text-center text-sm"
                >
                    <span>Bolt System © {{ year }}</span>

                    <p>
                        Made with
                        <span class="text-lg text-red-500">&#9829;</span> in
                        Nashville
                    </p>
                </div>
            </div>
        </div>
    </v-container>
</template>
