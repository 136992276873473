import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { aliases, fa } from 'vuetify/iconsets/fa-svg'

export function useVuetify() {
    const boltVuetifyTheme = {
        dark: false,
        colors: {
            background: '#F6F6F6',
            primary: '#181D1F',
            secondary: '#00715B',
        },
    }

    const vuetify = createVuetify({
        components: {
            VDateInput,
        },
        theme: {
            defaultTheme: 'boltVuetifyTheme',
            themes: {
                boltVuetifyTheme,
            },
        },
        icons: {
            defaultSet: 'fa',
            aliases,
            sets: {
                fa,
            },
        },
    })

    return { vuetify }
}
