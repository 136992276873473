import Client from 'App/Api/Client'
import { defineStore } from 'pinia'
import { find } from './helpers/getters'
import helperActions from './helpers/actions'
import StateFactory from './helpers/StateFactory'

/**
 * The API client instance.
 *
 * @type  {Client}
 */
const api = new Client()

/**
 * The Truck store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useTruckStore = defineStore('trucks', {
    /**
     * The truck's state.
     *
     * @return {Object}
     */
    state: () =>
        StateFactory.paginatedEntity({
            active: null,
        }),
    getters: {
        find,

        /**
         * Get the active truck.
         *
         * @param {Object} state
         * @return {Object}
         */
        activeTruck(state) {
            return state.items[state.active]
        },

        /**
         * Get the trucks.
         *
         * @param {Object} state
         * @return {Array}
         */
        trucks(state) {
            return state.ids.map((id) => state.items[id])
        },
    },

    /**
     * The truck's actions.
     *
     * @type {Object}
     */
    actions: {
        /**
         * Fetch a truck by id.
         *
         * @param {Number} id
         * @return {Promise}
         */
        async fetchTruck(id) {
            const response = await api.fetchTruck(id)

            helperActions.add(this.$state, response.get('data', {}))

            return response
        },

        /**
         * Get all the trucks.
         *
         * @return {Promise}
         */
        async get() {
            const response = await api.trucks()

            helperActions.add(this.$state, response.get('data', []))

            return response
        },

        /**
         * Set the active truck id.
         *
         * @param {Number} id
         */
        setActive(id) {
            this.active = id
        },
    },
})
