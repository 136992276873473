import http from 'axios'
import { env } from 'App/Helpers'

/**
 * Make the default shared Axios instance.
 *
 * @type {Axios}
 */
export const axios = http.create({
    baseURL: env('BASE_API_URL', ''),
})
