/**
 * Constant representing the in-transit dispatch tab.
 */
export const ALL_ACTIVE_TAB = 'all'

/**
 * Constant representing the in-transit dispatch tab.
 */
export const INTRANSIT_TAB = 'intransit'

/**
 * Constant representing the scheduled dispatch tab.
 */
export const SCHEDULED_TAB = 'scheduled'

/**
 * Constant representing the pending dispatch tab.
 */
export const PENDING_TAB = 'pending'

/**
 * Constant representing the recently completed dispatch tab.
 */
export const RECENTLY_COMPLETED_TAB = 'recently_completed'

/**
 * Constant representing the default dispatch tabs.
 */
export const TABS = [
    INTRANSIT_TAB,
    SCHEDULED_TAB,
    PENDING_TAB,
    RECENTLY_COMPLETED_TAB,
]

/**
 * Constant representing the delete load action.
 *
 * @type {String}
 */
export const DELETE_LOAD = 'modal:loads:delete'

/**
 * Constant representing the email driver action.
 *
 * @type {String}
 */
export const EMAIL_DRIVER = 'modal:driver:email'

/**
 * Constant representing the in-transit load status.
 *
 * @type {String}
 */
export const IN_TRANSIT = 'INTRANSIT'

/**
 * Constant representing the send ELD action.
 *
 * @type {String}
 */
export const SEND_ELD = 'modal:eld:send'

/**
 * Constant representing the update assigned driver action.
 *
 * @type {String}
 */
export const UPDATE_DRIVER = 'modal:driver:update'

/**
 * Constant representing the update load status action.
 *
 * @type {String}
 */
export const UPDATE_STATUS = 'modal:status:update'

/**
 * Constant representing the update assigned truck action.
 *
 * @type {String}
 */
export const UPDATE_TRUCK = 'modal:truck:update'

/**
 * Constant representing the update truck location action.
 *
 * @type {String}
 */
export const UPDATE_TRUCK_LOCATION = 'modal:truck:location:update'

/**
 * Constant representing the view load comments action.
 *
 * @type {String}
 */
export const VIEW_COMMENTS = 'modal:comments:view'

/**
 * Constant representing the view load action.
 *
 * @type {String}
 */
export const VIEW_LOAD = 'drawer:load:view'

/**
 * Constant representing all load actions that contain popups (modals, drawers, etc).
 */
export const ACTIONS = [
    DELETE_LOAD,
    EMAIL_DRIVER,
    SEND_ELD,
    UPDATE_TRUCK,
    UPDATE_DRIVER,
    UPDATE_STATUS,
    VIEW_COMMENTS,
    VIEW_LOAD,
    UPDATE_TRUCK_LOCATION,
]
