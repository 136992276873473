import Status from '../Status'

/**
 * Base API response.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class Response {
    /**
     * Create a new Response instance.
     *
     * @param {Object} response
     */
    constructor({ data, config, headers, status, statusText, request } = {}) {
        /**
         * The body of the response.
         *
         * @type {Object}
         */
        this.response = data || {}

        /**
         * The configuration used to make the request.
         *
         * @type {Object}
         */
        this.config = config

        /**
         * The HTTP response headers.
         *
         * @type {Object}
         */
        this.headers = headers

        /**
         * The HTTP response status code.
         *
         * @type {Number}
         */
        this.status = status || Status.SERVER_ERROR

        /**
         * The HTTP response status text.
         *
         * @type {String}
         */
        this.statusText = statusText

        /**
         * The URL the response was returned from.
         *
         * @type {String}
         */
        this.url = request ? request.responseURL : config ? config.url : ''
    }

    /**
     * Get the given key from the response property.
     *
     * @param {?String} key
     * @param {*} defaultValue
     * @return {*}
     */
    get(key = null, defaultValue = null) {
        if (key) {
            return this.response[key] || defaultValue
        }

        return this.response
    }

    /**
     * Alias to get the response's message.
     *
     * @return {String}
     */
    getMessage() {
        return this.message()
    }

    /**
     * Get the HTTP response status code.
     *
     * @return {Number}
     */
    getStatusCode() {
        return this.status
    }

    /**
     * Get the response message type.
     *
     * @return {String}
     */
    getType() {
        return this.response.type || this.getDefaultType()
    }

    /**
     * Determine if a response has a message.
     *
     * @return {Boolean}
     */
    hasMessage() {
        return !!this.get('message')
    }

    /**
     * Determine if the request was successful.
     *
     * @return {Boolean}
     */
    isSuccess() {
        return this.status >= 200 && this.status < 300
    }

    /**
     * Get the message from the response.
     *
     * @return {String}
     */
    message() {
        return this.get('message')
    }
}
