import Request from 'App/Http/Request'

/**
 * Bolt System API Client.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class Client {
    /**
     * Make a new Client instance.
     *
     * @param {Request} http
     */
    constructor(http) {
        this.http = http || new Request()
    }

    /**
     * Get all the billable customers.
     *
     * @return  {Promise}
     */
    billTo() {
        return this.http.get('boltAppRoot/api/v1/billto/')
    }

    /**
     * Create a new load comment.
     *
     * @param {Number} options.loadId
     * @param {Object} options.comment
     * @param {Number} options.priority
     * @return {Promise}
     */
    createLoadComment({ loadId, comment, priority }) {
        return this.http.post(
            'boltAppRoot/api/v1/loads/comments/',
            { comment, priority, is_customer_visible: false },
            { params: { load_id: loadId } }
        )
    }

    /**
     * Get all billable customers.
     *
     * @return {Promise}
     */
    customers() {
        return this.http.get('boltAppRoot/api/v1/billto/')
    }

    /**
     * Delete a load.
     *
     * @param {Number} id
     * @return {Promise}
     */
    deleteLoad(id) {
        return this.http.delete('boltAppRoot/api/v1/loads/', {
            params: {
                id,
            },
        })
    }

    /**
     * Delete a load comment.
     *
     * @param {Number} id
     * @return {Promise}
     */
    deleteLoadComment(id) {
        return this.http.delete(`boltAppRoot/api/v1/loads/comments/`, {
            params: {
                id,
            },
        })
    }

    /**
     * Get all driver resources.
     *
     * @return {Promise}
     */
    drivers() {
        return this.http.get('boltAppRoot/api/v1/drivers/')
    }

    /**
     * Get all driver segments.
     *
     * @param {String} payload.start
     * @param {String} payload.end
     * @returns {Promise}
     */
    driverSegments(dateRange) {
        return this.http.get('boltAppRoot/api/v1/driver_segments/', {
            params: {
                ...dateRange,
            },
        })
    }

    /**
     * Get all electronic logging devices for the given load.
     *
     * @param {Number} id
     * @return {Promise}
     */
    elds(id) {
        return this.http.get('boltAppRoot/api/v1/loads/elds/', {
            params: {
                load_id: id,
            },
        })
    }

    /**
     * Send the load sheet to the driver's email.
     *
     * @param {Number} segmentId
     * @param {Array} drivers
     * @return {Promise}
     */
    emailDrivers(segmentId, drivers) {
        return this.http.post(
            'boltAppRoot/api/v1/load_segments/sheets/email/',
            { driver_ids: drivers },
            { params: { load_segment_id: segmentId } }
        )
    }

    /**
     * Get all equipment resources.
     *
     * @return {Promise}
     */
    equipment() {
        return this.http.get('boltAppRoot/api/v1/equipment/')
    }

    /**
     * Fetch a driver by id.
     *
     * @param {Number} id
     * @return {Promise}
     */
    fetchDriver(id) {
        return this.http.get('boltAppRoot/api/v1/drivers/', {
            params: {
                id,
            },
        })
    }

    /**
     * Fetch a load by id.
     *
     * @param {Number} id
     * @return {Promise}
     */
    fetchLoad(id) {
        return this.http.get('boltAppRoot/api/v1/loads/', {
            params: {
                id,
            },
        })
    }

    /**
     * Fetch a location by id.
     *
     * @param {Number} id
     * @return {Promise}
     */
    fetchLocation(id) {
        return this.http.get('boltAppRoot/api/v1/locations/', {
            params: {
                id,
            },
        })
    }

    /**
     * Fetch a trailer by id.
     *
     * @param {Number} id
     * @return {Promise}
     */
    fetchTrailer(id) {
        return this.http.get('boltAppRoot/api/v1/trailers/', {
            params: {
                id,
            },
        })
    }

    /**
     * Fetch a terminal by id.
     *
     * @param {Number} id
     * @return {Promise}
     */
    fetchTerminal(id) {
        return this.http.get('boltAppRoot/api/v1/terminals/', {
            params: {
                id,
            },
        })
    }

    /**
     * Fetch a truck by id.
     *
     * @param {Number} id
     * @return {Promise}
     */
    fetchTruck(id) {
        return this.http.get('boltAppRoot/api/v1/trucks/', {
            params: {
                id,
            },
        })
    }

    /**
     * Fetch a truck owner by id.
     *
     * @param {Number} id
     * @return {Promise}
     */
    fetchTruckOwner(id) {
        return this.http.get('boltAppRoot/api/v1/truck_owners/', {
            params: {
                id,
            },
        })
    }

    /**
     * Fetch a user by id.
     *
     * @param {String} payload.id
     * @returns {Promise}
     */
    fetchUser(id) {
        return this.http.get('boltAppRoot/api/v1/users/', {
            params: {
                id,
            },
        })
    }

    /**
     * Fetch a vendor by id.
     *
     * @param {Number} id
     * @return {Promise}
     */
    fetchVendor(id) {
        return this.http.get('boltAppRoot/api/v1/vendors/', {
            params: {
                id,
            },
        })
    }

    /**
     * Switch to the legacy UI.
     *
     * @return {Promise}
     */
    legacyUx() {
        return this.http.get(
            '/index.cfm?module=ajax&template=toggleLegacy&showLegacy=true'
        )
    }

    /**
     * Get all load comments for the given load.
     *
     * @param  {Number} id
     * @return {Promise}
     */
    loadComments(id) {
        return this.http.get('boltAppRoot/api/v1/loads/comments/', {
            params: {
                load_id: id,
            },
        })
    }

    /**
     * Get all or a filtered collection of loads.
     *
     * @param {String} status
     * @return {Promise}
     */
    loads(status = null) {
        const statuses = [
            'scheduled',
            'pending',
            'intransit',
            'in_transit',
            'delivered',
            'recently_completed',
        ]

        if (status && statuses.indexOf(status) === -1) {
            throw new Error(
                'The given status is not valid and must be scheduled, pending, intransit or delivered/recently_completed.'
            )
        }

        const params = {}

        if (status) {
            params.load_status = status
        }

        return this.http.get('boltAppRoot/api/v1/load_board/', {
            params,
        })
    }

    /**
     * Get all shipments for loads on the loadboard.
     *
     * @param {String} status
     * @return {Promise}
     */
    loadShipments(status = null) {
        const statuses = [
            'scheduled',
            'pending',
            'intransit',
            'in_transit',
            'delivered',
            'recently_completed',
        ]

        if (status && statuses.indexOf(status) === -1) {
            throw new Error(
                'The given status is not valid and must be scheduled, pending, intransit or delivered/recently_completed.'
            )
        }

        const params = {}

        if (status) {
            params.load_status = status
        }

        return this.http.get('boltAppRoot/api/v1/load_board/shipments/', {
            params,
        })
    }

    /**
     * Get the last year of load counts by day.
     *
     * @return  {Promise}
     */
    loadCountsByDay() {
        return this.http.get('boltAppRoot/api/v1/analytics/load-counts-by-day/')
    }

    /**
     * Fetch load statuses.
     *
     * @param {Number}
     * @return {Promise}
     */
    loadStatuses() {
        return this.http.get('boltAppRoot/api/v1/loads/statuses/')
    }

    /**
     * Get all location resources.
     *
     * @return {Promise}
     */
    locations() {
        return this.http.get('boltAppRoot/api/v1/locations/')
    }

    /**
     * Send a login request to create a new authenticated session.
     *
     * @param   {String}  username
     * @param   {String}  password
     * @return  {Promise}
     */
    login(username, password) {
        return this.http.post('boltAppRoot/api/v1/login/', {
            username,
            password,
        })
    }

    /**
     * Send a logout request to destroy the session.
     *
     * @return  {Promise}
     */
    logout() {
        return this.http.get('boltAppRoot/api/v1/logout/')
    }

    /**
     * Get the current authenticated user.
     *
     * @return {Promise}
     */
    me() {
        return this.http.get('boltAppRoot/api/v1/me/')
    }

    /**
     * Get the current users navigation menu items.
     *
     * @return {Promise}
     */
    nav() {
        return this.http.get('boltAppRoot/api/v1/nav/')
    }

    /**
     * Get all load sheets for the given load.
     *
     * @param {Number} id
     * @return {Promise}
     */
    printLoad(id) {
        return this.http.get('boltAppRoot/api/v1/load_segments/sheets/', {
            params: {
                load_segment_id: id,
            },
        })
    }

    /**
     * Send the load to the given electronic logging device.
     *
     * @param {Number} loadId
     * @param {Number} id
     * @return {Promise}
     */
    sendLoadToEld(loadId, id) {
        return this.http.post(
            'boltAppRoot/api/v1/loads/elds/',
            { id },
            {
                params: {
                    load_id: loadId,
                },
            }
        )
    }

    /**
     * Get all segments for the given load.
     *
     * @return {Promise}
     */
    segments(id) {
        return this.http.get('boltAppRoot/api/v1/loads/segments/', {
            params: {
                load_id: id,
            },
        })
    }

    /**
     * Get all shipments for the given load.
     *
     * @return {Promise}
     */
    shipments(id) {
        return this.http.get('boltAppRoot/api/v1/shipments/', {
            params: {
                load_id: id,
            },
        })
    }

    /**
     * Get all shippers resources.
     *
     * @return  {Promise}
     */
    shipTo() {
        return this.http.get('boltAppRoot/api/v1/shipto/')
    }

    /**
     * Get all terminals resources.
     *
     * @return {Promise}
     */
    terminals() {
        return this.http.get('boltAppRoot/api/v1/terminals/')
    }

    /**
     * Get all trailers resources.
     *
     * @return {Promise}
     */
    trailers() {
        return this.http.get('boltAppRoot/api/v1/trailers/')
    }

    /**
     * Get all truck resources.
     *
     * @return {Promise}
     */
    trucks() {
        return this.http.get('boltAppRoot/api/v1/trucks/')
    }

    /**
     * Get all truck segments.
     *
     * @param {String} payload.start
     * @param {String} payload.end
     * @returns {Promise}
     */
    truckSegments(dateRange) {
        return this.http.get('boltAppRoot/api/v1/truck_segments/', {
            params: {
                ...dateRange,
            },
        })
    }

    /**
     * Get all truck owner resources.
     *
     * @return {Promise}
     */
    truckOwners() {
        return this.http.get('boltAppRoot/api/v1/truck_owners/')
    }

    /**
     * Get the top billing customers.
     *
     * @return  {Promise}
     */
    topBillingCustomers() {
        return this.http.get(
            'boltAppRoot/api/v1/analytics/top-billing-customers/'
        )
    }

    /**
     * Get the top shipping customers.
     *
     * @return  {Promise}
     */
    topShippingCustomers() {
        return this.http.get(
            'boltAppRoot/api/v1/analytics/top-shipping-customers/'
        )
    }

    /**
     * Update a user.
     *
     * @param {String} payload.id
     * @param {Object} payload.user
     * @returns {Promise}
     */
    updateUser(payload) {
        return this.http.put('boltAppRoot/api/v1/users/', payload, {
            params: { id: payload.id },
        })
    }

    /**
     * Update the user's credentials.
     *
     * @param {String} payload.id
     * @param {Object} payload.user
     * @returns {Promise}
     */
    updateUserCredentials(payload) {
        return this.http.put(
            'boltAppRoot/api/v1/users/credentials',
            {
                user_name: payload.user_name,
                password: payload.password,
                password_confirmation: payload.password_confirmation,
            },
            { params: { id: payload.id } }
        )
    }

    /**
     * Update a load comment.
     *
     * @param {Number} options.id
     * @param {Object} options.driver
     * @param {Number} options.priority
     * @return {Promise}
     */
    updateDriver(payload) {
        return this.http.put(
            `boltAppRoot/api/v1/users/drivers/`,
            { ...payload },
            { params: { id: payload.id } }
        )
    }

    /**
     * Update a load comment.
     *
     * @param {Number} options.id
     * @param {Object} options.comment
     * @param {Number} options.priority
     * @return {Promise}
     */
    updateLoadComment({ id, comment, priority }) {
        return this.http.put(
            `boltAppRoot/api/v1/loads/comments/`,
            { comment, priority, is_customer_visible: false },
            { params: { id } }
        )
    }

    /**
     * Update the load status.
     *
     * @param   {Number} id
     * @param   {Number} status
     * @return  {void}
     */
    updateLoadStatus(id, status) {
        return this.http.patch(
            'boltAppRoot/api/v1/loads/',
            { load_status: status },
            { params: { id } }
        )
    }

    /**
     * Update the segment's drivers, truck, trailers and carrier.
     *
     * @param {Number} id
     * @param {Object} payload
     * @return {Promise}
     */
    updateSegment(id, payload) {
        return this.http.patch(
            'boltAppRoot/api/v1/loads/segments/',
            { ...payload },
            { params: { id } }
        )
    }

    /**
     * Update the truck's location.
     *
     * @param {Object} payload
     * @return {Promise}
     */
    updateTruckLocation(payload) {
        return this.http.post('boltAppRoot/api/v1/breadcrumbs/', { ...payload })
    }

    /**
     * Get all vendor resources.
     *
     * @return {Promise}
     */
    vendors() {
        return this.http.get('boltAppRoot/api/v1/vendors/')
    }
}
