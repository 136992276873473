import Client from 'App/Api/Client'
import { defineStore } from 'pinia'
import { find } from './helpers/getters'
import { useTruckStore } from './Trucks'
import { useDriverStore } from './Drivers'
import helperActions from './helpers/actions'
import { useTerminalStore } from './Terminals'
import { unshift, push } from './helpers/actions'
import StateFactory from './helpers/StateFactory'
import { useLoadSegmentsStore } from './LoadSegments'
import { useLoadShipmentsStore } from './LoadShipments'
import { TABS } from '@/constants/dispatch'

/**
 * The API client instance.
 *
 * @return {Client}
 */
const api = new Client()

/**
 * Loads state store.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export const useLoadsStore = defineStore('loads', {
    /**
     * Load's state.
     *
     * @return  {Object}
     */
    state: () =>
        StateFactory.entity({
            statuses: [],
        }),

    /**
     * Loads's getters.
     *
     * @type  {Object}
     */
    getters: {
        find,

        /**
         * Get all the loads.
         *
         * @param   {Object}  state
         * @return {Object}
         */
        loads(state) {
            return state.ids.map((id) => state.items[id])
        },

        /**
         * Get all recently completed loads.
         *
         * @return {Object}
         */
        recentlyCompletedLoads() {
            return this.loads.filter((load) => {
                return load.status.code === 'DELIVERED'
            })
        },
    },

    /**
     * Loads's actions.
     *
     * @type  {Object}
     */
    actions: {
        /**
         * Delete a load.
         *
         * @param {Number}  id
         * @return {Promise}
         */
        async delete(id) {
            const response = await api.deleteLoad(id)

            helperActions.remove(this.$state, id)

            return response
        },

        /**
         * Download Miler routing.
         *
         * @return {Promise}
         */
        async downloadMilerRouting() {
            // TODO: download Miler routing
        },

        /**
         * Fetch a load.
         *
         * @param {Number}  id
         * @return {Promise}
         */
        async fetchLoad(id) {
            const response = await api.fetchLoad(id)

            const data = response.get('data', {})

            helperActions.add(this.$state, data)

            return response
        },

        /**
         * Fetch all statuses.
         *
         * @return {Promise}
         */
        async fetchStatuses() {
            const response = await api.loadStatuses()

            this.$state.statuses = response.get('data', {})

            return response
        },

        /**
         * Get all (loadboard) loads with the given status(es).
         *
         * @param {Array} statuses
         * @return {Promise}
         */
        async getLoadboardWithStatuses(statuses) {
            statuses = [].concat(statuses || [])

            const store = useLoadShipmentsStore()
            const driverStore = useDriverStore()
            const truckStore = useTruckStore()
            const terminalStore = useTerminalStore()

            const promises = statuses.map((status) => {
                return api.loads(status)
            })

            const responses = await Promise.allSettled(promises)

            await store.getShipmentsByLoadStatuses(statuses)

            responses.forEach((promise) => {
                if (promise.status !== 'fulfilled') {
                    return console.error(promise.reason)
                }

                const loads = promise.value.get('data', [])

                /*
                 * Client Side hyrdration of load objects (shipments, drivers, truck, etc.).
                 */
                loads.forEach((load) => {
                    load.purchase_order_names =
                        store.getPurchaseOrderNameForLoad(load.id)

                    load.drivers =
                        driverStore.getDriversById(load.driver_ids) || []

                    load.truck = load.truck_id
                        ? truckStore.find(load.truck_id) || null
                        : null

                    load.origination_terminal = load.origination_terminal_id
                        ? terminalStore.find(load.origination_terminal_id)
                        : null
                })

                helperActions.add(this.$state, loads)
            })

            return responses
        },

        /**
         * Get all loadboard loads in four separate concurrent requests.
         *
         * @returns {Promise}
         */
        async getAll() {
            const promises = TABS.map((name) => {
                return api.loads(name)
            })

            const responses = await Promise.all(promises)

            const store = useLoadShipmentsStore()
            const driverStore = useDriverStore()
            const truckStore = useTruckStore()
            const terminalStore = useTerminalStore()

            /** Fetch all shipments for loads displayed on the loadboard */
            await store.getAll()

            responses.forEach((response) => {
                const loads = response.get('data', [])

                /*
                 * Client Side hyrdration of load objects (shipments, drivers, truck, etc.).
                 */
                loads.forEach((load) => {
                    load.purchase_order_names =
                        store.getPurchaseOrderNameForLoad(load.id)

                    load.drivers =
                        driverStore.getDriversById(load.driver_ids) || []

                    load.truck = load.truck_id
                        ? truckStore.find(load.truck_id) || null
                        : null

                    load.origination_terminal = load.origination_terminal_id
                        ? terminalStore.find(load.origination_terminal_id)
                        : null
                })

                helperActions.add(this.$state, loads)
            })

            return responses
        },

        /**
         * Set the current segment id for the given load id.
         *
         * @param {Number} id
         * @return {String}
         */
        setCurrentSegmentId(id) {
            const load = this.$state.items[id]

            const store = useLoadSegmentsStore()

            const segmentIds = store.ids.filter((segmentId) => {
                return store.items[segmentId].load_id === id
            })

            const segments = segmentIds.map(
                (segmentId) => store.items[segmentId]
            )

            const current = segments.find((segment) => {
                return segment.stop_ids.indexOf(load.current_stop.id) > -1
            })

            if (load.current_load_segment_id !== current?.id) {
                unshift(this.$state, load)

                load.current_load_segment_id = current?.id || null

                push(this.$state, load)
            }

            return current?.id || null
        },

        /**
         * Print a load.
         *
         * @param {Number}  id
         * @return {Promise}
         */
        async printLoad(id) {
            const response = await api.printLoad(id)

            const data = response.get('data', {})

            return data.url
        },

        /**
         * Update load's driver.
         *
         * @param {Number}  loadId
         * @param {Number}  segmentId
         * @param {Number}  id
         * @return {Promise}
         */
        async updateDriver(loadId, segmentId, id) {
            const response = await api.updateSegment(segmentId, {
                driver_ids: [].concat(id),
            })

            const data = response.get('data', {})

            const drivers = data.drivers

            const load = {
                ...this.$state.items[loadId],
                drivers,
            }

            await helperActions.add(this.$state, load)

            return response
        },

        /**
         * Update a truck.
         *
         * @param {String} id
         * @param {Number} loadId
         * @param {Object} payload
         * @return {Promise}
         */
        async updateSegment(id, loadId, payload) {
            const response = await api.updateSegment(id, payload)

            const data = response.get('data', {})

            const truck = data.truck

            const load = {
                ...this.$state.items[loadId],
                truck,
            }

            helperActions.add(this.$state, load)

            return response
        },

        /**
         * Set truck location.
         *
         * @param {Number}  payload.id
         * @param {Object}  payload.payload
         * @return {Promise}
         */
        async setTruckLocation({ loadId, payload }) {
            const response = await api.updateTruckLocation(payload)

            const data = response.get('data', {})

            const currentLocation = {
                ...this.$state.items[loadId]?.current_location,
            }

            const load = {
                ...this.$state.items[loadId],
                current_location: {
                    ...currentLocation,
                    ...data,
                },
            }

            helperActions.add(this.$state, load)

            return response
        },

        /**
         * Update the current truck and drivers for the given load.
         *
         * @param {Number} loadId
         * @param {Object} payload.truck
         * @param {Array} payload.drivers
         * @return {void}
         */
        updateCurrentTruckAndDrivers(loadId, payload) {
            const load = {
                ...this.$state.items[loadId],
                ...payload,
            }

            helperActions.add(this.$state, load)
        },

        /**
         * Update load status.
         *
         * @param {Number}  id
         * @param {Object}  payload
         * @return {Promise}
         */
        async updateStatus(id, status) {
            const response = await api.updateLoadStatus(id, status)

            const data = response.get('data', {})

            const load = {
                ...this.$state.items[id],
                status: data.status,
            }

            helperActions.add(this.$state, load)

            return response
        },
    },
})
