<template>
    <v-app>
        <v-main id="main-container">
            <toast />
            <router-view ref="view" :key="$route.fullPath" />
        </v-main>
    </v-app>
</template>

<script>
import Toast from '@/components/shared/Toast.vue'

export default {
    /**
     * The component's registered child components.
     *
     * @type {Object}
     */
    components: {
        Toast,
    },

    /**
     * The component's name used for debugging.
     *
     * @type {String}
     */
    name: 'App',

    /**
     * The component's mounted lifecycle hook.
     *
     * @return {void}
     */
    mounted() {
        window.app = this
    },
}
</script>

<style lang="scss">
@import './sass/app.scss';
</style>
