import HoursOfService from './HoursOfService'

/**
 * Driver model.
 *
 * <@author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export default class Driver {
    /**
     * Create a new Driver instance.
     *
     * @param {Object} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes)
    }

    /**
     * Create a new Hours of Service instance.
     *
     * @return {HoursOfService}
     */
    hoursOfService() {
        return new HoursOfService(this.driver.hours_of_service)
    }
}
