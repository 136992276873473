import Task from './Task'
import { axios } from 'App/Http'
import interceptors from '@/config/interceptors'

/**
 * Configure the axios instance & add the request/response interceptors.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class ConfigureAxios extends Task {
    /**
     * Run the asynchronous task and resolve when complete.
     *
     * @param {Function} next
     * @return {void}
     */
    async run(next) {
        const http = axios

        this._addRequestInterceptors(http)
        this._addResponseInterceptors(http)

        next()
    }

    /**
     * Add a request interceptor to axios.
     *
     * @param {Axios} http
     * @param {Function} interceptor
     * @return {void}
     */
    _addRequestInterceptor(http, interceptor) {
        http.interceptors.request.use(interceptor, (error) =>
            Promise.reject(error)
        )
    }

    /**
     * Add each of the request interceptors.
     *
     * @param {Axios} http
     * @return {void}
     */
    _addRequestInterceptors(http) {
        interceptors.request.forEach((interceptor) => {
            this._addRequestInterceptor(http, interceptor)
        })
    }

    /**
     * Add a response interceptor to http.
     *
     * @param {Axios} http
     * @param {Object} interceptor
     * @return {void}
     */
    _addResponseInterceptor(http, { success, error }) {
        if (!success) {
            success = (response) => response
        }

        if (!error) {
            error = (error) => Promise.reject(error)
        }

        http.interceptors.response.use(success, error)
    }

    /**
     * Add each of the response interceptors.
     *
     * @param {Axios} http
     * @return {void}
     */
    _addResponseInterceptors(http) {
        interceptors.response.forEach((interceptor) => {
            this._addResponseInterceptor(http, interceptor)
        })
    }
}
