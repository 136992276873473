import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

/**
 * The dayjs plugin.
 */
dayjs.extend(relativeTime)

/**
 * Hours of service model.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export default class HoursOfService {
    /**
     * Create a new Hours of Service instance.
     *
     * @param {Object} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes)
    }

    /**
     * The drive remaining time.
     *
     * @return {String}
     */
    driveTimeRemaining() {
        if (!this.drive_remaining) {
            return null
        }

        const driveRemaining = Math.abs(this.drive_remaining)
        const hours = Math.floor(driveRemaining / 60)
        const remainingMinutes = driveRemaining % 60
        const prefix = this.drive_remaining < 0 ? '-' : ''

        const time = dayjs()
            .startOf('day')
            .add(hours, 'hour')
            .add(remainingMinutes, 'minute')
            .format('H:mm')

        return `${prefix}${time}`
    }

    /**
     * The on duty remaining time.
     *
     * @param  {Object} driver
     * @return {String}
     */
    onDutyRemainingTime() {
        if (!this?.on_duty_remaining) {
            return
        }

        const dutyRemaining = Math.abs(this.on_duty_remaining)

        const hours = Math.floor(dutyRemaining / 60)
        const remainingMinutes = dutyRemaining % 60
        const prefix = this.on_duty_remaining < 0 ? '-' : ''

        const time = dayjs()
            .startOf('day')
            .add(hours, 'hour')
            .add(remainingMinutes, 'minute')
            .format('H:mm')

        return `${prefix}${time}`
    }

    /**
     * Determine if the event has on duty and drive time remaining.
     *
     * @return {Boolean}
     */
    hasOnDutyAndDriveTimeRemaining() {
        return !!(this.onDutyRemainingTime() && this.driveTimeRemaining())
    }

    /**
     * Get the driver's hos updated at.
     *
     * @return {String}
     */
    hosUpdatedAt() {
        if (!this.updated_at) {
            return null
        }

        return dayjs().from(dayjs(this.updated_at), 'day')
    }
}
