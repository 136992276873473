import Task from './Task'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fass } from '@fortawesome/sharp-solid-svg-icons'
import { fasr } from '@fortawesome/sharp-regular-svg-icons'

/**
 * Install and configure additional Vue plugins and libraries.
 *
 * @author Erik Galloway <egalloway@boltsystem.com>
 */
export default class InstallVuePlugins extends Task {
    /**
     * Run the asynchronous task and resolve when complete.
     *
     * @param {Function} next
     * @return {void}
     */
    async run(next) {
        this.installFontAwesome()

        next()
    }

    /**
     * Install the font awesome icon set.
     *
     * @return {void}
     */
    installFontAwesome() {
        library.add(fas)
        library.add(fab)
        library.add(far)
        library.add(fal)
        library.add(fat)
        library.add(fad)
        library.add(fass)
        library.add(fasr)

        this.app.component('font-awesome-icon', FontAwesomeIcon)
    }
}
