import Task from './Task'
import { useAuthStore } from '@/stores/Auth'

/**
 * The application's authentication check task.
 *
 * @author Alejandro Sanchez <asanchez@boltsystem.com>
 */
export default class AuthCheck extends Task {
    /**
     * Run the asynchronous task and resolve when complete.
     *
     * @param {Function} next
     * @return {void}
     */
    async run(next) {
        const authStore = useAuthStore()

        try {
            await authStore.get()
        } catch (error) {
            console.error(error)
        }

        next()
    }
}
